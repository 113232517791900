import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { toggleTooltipActivation } from '../serverConfigs/serverConfigs.slice';

import {
  GetProjectIdRequest,
  IProject,
  SendProjectIdRequest,
} from './project.types';

export const fetchProjectList = createAsyncThunk<IProject[]>(
  'project/fetchList',
  async () => {
    const url = '/home/project/list';
    try {
      const response = await axios.post(url);
      const projects = response.data;
      return projects;
    } catch (error) {
      console.error('Error fetching project list:', error);
      throw new Error('Failed to fetch project list');
    }
  },
);

export const sendProjectId = createAsyncThunk<
  GetProjectIdRequest,
  SendProjectIdRequest
>('project/sendProjectId', async requestData => {
  const url = '/home/project/choose';
  try {
    const response = await axios.post(url, requestData);
    if (response.status === 200) {
      return response.data;
    }
    throw new Error('Received unsuccessful response from the backend');
  } catch (error) {
    console.error('Error sending projectId:', error);
    throw new Error('Failed to send projectId');
  }
});

export interface UpdateTimezoneRequest {
  timezone: string;
}

export const updateTimezone = createAsyncThunk<UpdateTimezoneRequest, string>(
  'project/updateTimezone',
  async timezone => {
    const url = '/auth/update-profile';
    try {
      const response = await axios.post(url, { timezone });
      if (response.status === 200) {
        return response.data;
      }
      throw new Error('Received unsuccessful response from the backend');
    } catch (error) {
      console.error('Error updating timezone:', error);
      throw new Error('Failed to update timezone');
    }
  },
);

export const updateTooltipeSettingsThunks = createAsyncThunk<boolean, boolean>(
  'configs/updateTooltipeSettings',
  async (val, thunkAPI) => {
    const url = '/auth/update-profile';
    try {
      const response = await axios.post(url, { is_tooltipe_active: val });
      if (response.status === 200) {
        thunkAPI.dispatch(toggleTooltipActivation(val));
        return response.data;
      }
      throw new Error('Received unsuccessful response from the backend');
    } catch (error) {
      console.error('Error updating timezone:', error);
      throw new Error('Failed to update timezone');
    }
  },
);
