/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
import { changePaymentStatus, getPaymentData } from './paymentConfigs.thunks';
import { PaymentDisplay } from './paymentConfigs';

interface PageDataState {
  loading: boolean;
  data: PaymentDisplay;
  error: string | null;
  lastUpdated: number;
}

const initialState: PageDataState = {
  loading: false,
  data: [],
  error: null,
  lastUpdated: Date.now(),
};

const paymentConfigsSilce = createSlice({
  name: 'paymentConfigs',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getPaymentData.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPaymentData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getPaymentData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'An error occurred';
      })
      .addCase(changePaymentStatus.pending, state => {
        state.loading = true;
      })
      .addCase(changePaymentStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.lastUpdated = Date.now();
      })
      .addCase(changePaymentStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Failed to change status';
      });
  },
});

export const {} = paymentConfigsSilce.actions;

export default paymentConfigsSilce.reducer;
