import { Navigate } from 'react-router-dom';
import {
  DeploymentUnitOutlined,
  DollarOutlined,
  HomeOutlined,
  SearchOutlined,
  SettingOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';

import AuthLayout from '../Layouts/AuthLayout/AuthLayout';
import i18n from '../../assets/translations';

import ChangePassword from './ChangePassword/ChangePassword';
import Login from './Login/Login';
import Settings from './Settings/Settings';
import { IAclPath, IRoutePath, IMenuRoute } from './routes.types';
import { PasswordChangeNeedRestrict } from './routes.restricts';
import UsersForAdmin from './AdminPanel/UsersForAdmin/UsersForAdmin';
import Dashboard from './Dashboard/Dashboard';
import SEO from './SEO/SEO';
import Managment from './Payments/Managment/Managment';

import type { RouteObject } from 'react-router-dom';

export const LOGOUT_ROUTES: RouteObject[] = [
  {
    path: '/',
    element: <AuthLayout />,
    children: [
      {
        index: true,
        element: <Navigate to='/login' />,
      },
      {
        path: '/login',
        element: (
          <PasswordChangeNeedRestrict isPasswordPage={false}>
            <Login />
          </PasswordChangeNeedRestrict>
        ),
      },
      {
        path: '/create-password',
        element: (
          <PasswordChangeNeedRestrict isPasswordPage>
            <ChangePassword />
          </PasswordChangeNeedRestrict>
        ),
      },
      {
        path: '*',
        element: <Navigate to='/login' />,
      },
    ],
  },
];

export const MENU_ROUTES: IMenuRoute[] = [
  {
    aclPath: IAclPath.statistics,
    path: IRoutePath.dashboard,
    icon: <HomeOutlined />,
    label: i18n.t('Dashboard'),
    element: <Dashboard />,
  },
  {
    icon: <DollarOutlined />,
    label: i18n.t('Payments'),
    children: [
      {
        path: IRoutePath.payment_list,
        label: i18n.t('Managment'),
        element: <Managment />,
      },
      {
        // path: IRoutePath.analytics,
        label: i18n.t('Analytics'),
        element: <SEO />,
      },
    ],
  },
  {
    aclPath: IAclPath.crm_meta_data_list,
    path: IRoutePath.seo,
    icon: <SearchOutlined />,
    label: i18n.t('SEO'),
    children: [
      {
        path: IRoutePath.seo_pages,
        label: i18n.t('Pages'),
        element: <SEO />,
      },
    ],
  },
  {
    // aclPath: IAclPath.admin,
    icon: <DeploymentUnitOutlined />,
    label: i18n.t('Admin Panel'),
    children: [
      {
        aclPath: IAclPath.admin_users_list,
        path: IRoutePath.admin_for_users,
        icon: <TeamOutlined />,
        label: i18n.t('Users'),
        element: <UsersForAdmin />,
      },
    ],
  },
  {
    icon: <SettingOutlined />,
    label: i18n.t('Settings'),
    children: [
      {
        path: IRoutePath.setting_account,
        icon: <UserOutlined />,
        label: i18n.t('Account'),
        element: <Settings />,
      },
    ],
  },
];
