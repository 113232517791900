import { FC, useEffect, useState } from 'react';
import { Table, Button, Tag, Dropdown, MenuProps, Modal } from 'antd';
import { DownOutlined, ReloadOutlined } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from 'redux/hooks/redux.hooks';
import {
  changePaymentStatus,
  getPaymentData,
  reloadSettings,
} from 'redux/reducers/paymentConfigs/paymentConfigs.thunks';
import { PaymentItem } from 'redux/reducers/paymentConfigs/paymentConfigs';
import { selectProjectName } from 'redux/reducers/projects/projectList.slice';

const Management: FC = () => {
  const dispatch = useAppDispatch();
  const payments = useAppSelector(state => state.paymentConfigs.data);
  const loading = useAppSelector(state => state.paymentConfigs.loading);
  const lastUpdated = useAppSelector(state => state.paymentConfigs.lastUpdated);
  const selectedProjectName = useAppSelector(selectProjectName);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState<PaymentItem | null>(null);
  const [actionType, setActionType] = useState('');
  const [isRestartModalVisible, setIsRestartModalVisible] = useState(false); // State for restart modal

  useEffect(() => {
    dispatch(getPaymentData());
  }, [dispatch, lastUpdated, selectedProjectName]);

  const showConfirmModal = (record: PaymentItem, action: string) => {
    setCurrentRecord(record);
    setActionType(action);
    setIsModalVisible(true);
  };

  const handleConfirm = () => {
    if (currentRecord) {
      const { name, method, ussd, withdraw, online } = currentRecord;

      let withdrawStatus = withdraw;
      let depositStatus = online;

      if (method === 'Withdraw') {
        withdrawStatus = actionType === 'enable' ? 1 : 0;
      } else if (method === 'Deposit') {
        depositStatus = actionType === 'enable' ? 1 : 0;
      }

      const config = {
        withdraw: withdrawStatus,
        ussd,
        online: depositStatus,
      };
      dispatch(changePaymentStatus({ operator: name, config }));
    }
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleMenuClick = (record: PaymentItem, key: string) => {
    showConfirmModal(record, key);
  };

  const menu = (record: PaymentItem): MenuProps => ({
    onClick: ({ key }) => handleMenuClick(record, key),
    items: [
      { key: 'enable', label: 'Enable' },
      { key: 'disable', label: 'Disable' },
    ],
  });

  const columns = [
    {
      title: 'Payment Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Payment Method',
      key: 'method',
      render: (record: PaymentItem) => {
        return `${record.method}`;
      },
    },
    {
      title: 'Status',
      key: 'status',
      render: (record: PaymentItem) => (
        <Tag color={record.status === 'Disabled' ? '#d48806' : '#389e0d'}>
          {record.status}
        </Tag>
      ),
    },
    {
      title: 'Change Status',
      key: 'action',
      render: (record: PaymentItem) => (
        <Dropdown menu={menu(record)} trigger={['click']}>
          <Button>
            {record.status}
            <DownOutlined />
          </Button>
        </Dropdown>
      ),
    },
  ];

  // Handle Restart modal confirm and cancel
  const handleRestartConfirm = async () => {
    dispatch(reloadSettings()); // Example: reload data
    setIsRestartModalVisible(false);
  };

  const handleRestartCancel = () => {
    setIsRestartModalVisible(false);
  };

  return (
    <>
      <Button
        icon={<ReloadOutlined />}
        onClick={() => setIsRestartModalVisible(true)} // Show restart modal on click
        style={{ marginBottom: 16, float: 'right' }}>
        Reload settings
      </Button>

      <Table
        columns={columns}
        dataSource={payments}
        loading={loading}
        rowKey='key'
      />

      <Modal
        title={`Are you sure you want to ${actionType} ${currentRecord?.name}?`}
        open={isModalVisible}
        onOk={handleConfirm}
        onCancel={handleCancel}
        okText='Yes'
        cancelText='No'>
        <p>{`Are you sure that you want to ${actionType} the "${currentRecord?.name}" method?`}</p>
      </Modal>

      <Modal
        title='Are you sure you want to restart?'
        open={isRestartModalVisible} // Restart modal
        onOk={handleRestartConfirm}
        onCancel={handleRestartCancel}
        okText='Yes'
        cancelText='No'>
        <p>This will reload the settings and. Do you want to proceed?</p>
      </Modal>
    </>
  );
};

export default Management;
