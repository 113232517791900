/* eslint-disable */

import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from 'axios';
import {
  ChangePaymentStatusPayload,
  PaymentDisplay,
  PaymentMethodData,
  Payments,
} from './paymentConfigs';

export const getPaymentData = createAsyncThunk<
  PaymentDisplay,
  void,
  { rejectValue: string }
>('configs/list', async (_, { rejectWithValue }) => {
  try {
    const response = await axios.post<{ [key: string]: PaymentMethodData }>(
      '/configs/list',
    );
    const transformedData = transformData(response.data);
    return transformedData;
  } catch (error) {
    return rejectWithValue('Failed to fetch data');
  }
});

export const changePaymentStatus = createAsyncThunk(
  'paymentConfigs/changeStatus',
  async (payload: ChangePaymentStatusPayload, { rejectWithValue }) => {
    try {
      const response = await axios.post('/configs/update', payload);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const reloadSettings = createAsyncThunk(
  'paymentConfigs/reloadSettings',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post('/configs/reloadSettings', payload);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

const transformData = (data: {
  [key: string]: PaymentMethodData;
}): PaymentDisplay => {
  const transformed: PaymentDisplay = [];
  Object.entries(data).forEach(([name, methods]) => {
    if (name !== 'N/A') {
      transformed.push({
        key: `${name}-deposit`,
        name,
        method: 'Deposit',
        status: methods.online ? 'Enabled' : 'Disabled',
        ussd: methods.ussd,
        withdraw: methods.withdraw,
        online: methods.online,
      });
      transformed.push({
        key: `${name}-withdraw`,
        name,
        method: 'Withdraw',
        status: methods.withdraw ? 'Enabled' : 'Disabled',
        ussd: methods.ussd,
        withdraw: methods.withdraw,
        online: methods.online,
      });
    }
  });
  return transformed;
};
