import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'redux/store.types';

import { fetchProjectList, sendProjectId } from './project.thunks';
import { IProject } from './project.types';

interface ProjectDataType {
  loading: boolean;
  projects: IProject[];
  selectedProjectName: number | null;
}

const initialState: ProjectDataType = {
  loading: false,
  projects: [],
  selectedProjectName: null,
};

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    // setProjects: (action: PayloadAction<IProject[]>) => {
    //   return action.payload;
    // },
  },
  extraReducers: builder => {
    builder.addCase(fetchProjectList.fulfilled, (state, action) => {
      state.projects = action.payload;
    });
    builder.addCase(sendProjectId.fulfilled, (state, action) => {
      state.selectedProjectName = action.payload.id;
    });
  },
});

// ACTIONS
// export const {} = projectSlice.actions;

// SELECTORS
export const selectProjects = (state: RootState) => state.project.projects;
export const selectProjectName = (state: RootState) =>
  state.project.selectedProjectName;

export default projectSlice.reducer;
