import { FC } from 'react';
import { Drawer } from 'antd';
import { useSelector } from 'react-redux';

import { IProject } from 'redux/reducers/projects/project.types';
import { RootState } from 'redux/store.types';

import Project from './Project';
import classes from './Project.module.scss';

interface ProjectDrawerProps {
  visible: boolean;
  onClose: () => void;
  onProjectSelect: (projectId: number) => void;
}

const ProjectDrawer: FC<ProjectDrawerProps> = ({
  visible,
  onClose,
  onProjectSelect,
}) => {
  const projectList = useSelector((state: RootState) => state.project.projects);

  return (
    <Drawer
      visible={visible}
      onClose={onClose}
      height='100vh'
      placement='top'
      closable={false}
      mask={false}
      getContainer={false}
      style={{ position: 'fixed', top: 0 }}
      destroyOnClose>
      <div className={classes.projectContainer}>
        {projectList.map((item: IProject) => (
          <Project
            data={item}
            key={item.project}
            onProjectSelect={() => onProjectSelect(item.id)}
          />
        ))}
      </div>
    </Drawer>
  );
};

export default ProjectDrawer;
