import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs } from 'antd';

import ProjectDrawer from 'components/Common/Projects/ProjectDrawer';
import { fetchAllData } from 'redux/reducers/dashboard/dashboard.thunks';
import {
  fetchProjectList,
  sendProjectId,
  updateTimezone,
} from 'redux/reducers/projects/project.thunks';
import {
  selectAuthUserInfo,
  setCurrentProject,
  setCurrentTimezone,
} from 'redux/reducers/serverConfigs/serverConfigs.slice';
import { AppDispatch, RootState } from 'redux/store.types';
import { IFinance, IPayment } from 'redux/reducers/dashboard/dashboard.types';
import {
  selectFinanceData,
  selectPaymentsData,
} from 'redux/reducers/dashboard/dashboard.slice';
import GlobalLoader from 'components/Common/GlobalLoader/GlobalLoader';

import classes from './Dashboard.module.scss';
import FinanceInfo from './DashboardSections/FinanceInfo';
import FiltersContainer from './DashboardSections/FiltersContainer';
import GeneralInfo from './DashboardSections/GeneralInfo';
import PaymentsInfo from './DashboardSections/PaymentsInfo';
import MobileFilters from './DashbordMobile/MobileFilters';
import { tabsConfig } from './DashbordMobile/tabsConfig';

const Dashboard: FC = () => {
  const project = useSelector(selectAuthUserInfo);
  const dispatch = useDispatch<AppDispatch>();
  const financeData = useSelector<RootState, IFinance[]>(selectFinanceData);
  const paymentData = useSelector<RootState, IPayment[]>(selectPaymentsData);
  const [isSmallScreen, setIsSmallScreen] = useState(
    window.innerWidth <= 1000 && window.innerWidth <= 1000,
  );
  const isLoading = useSelector<RootState, boolean>(
    state => state.dashboard.isLoading,
  );
  const [showDrawer, setShowDrawer] = useState(
    !project.meta.currentProject?.project,
  );

  useEffect(() => {
    const fetchData = async () => {
      if (project?.timezone === '' && project?.meta.currentProject?.id) {
        try {
          const offsetMinutes = new Date().getTimezoneOffset();
          const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
          const offsetMinutesRemainder = Math.abs(offsetMinutes) % 60;
          const offsetFormatted = `${
            offsetHours < 10 ? '0' : ''
          }${offsetHours}:${
            offsetMinutesRemainder < 10 ? '0' : ''
          }${offsetMinutesRemainder}`;
          await dispatch(
            updateTimezone(
              offsetMinutes < 0 ? `+${offsetFormatted}` : `-${offsetFormatted}`,
            ),
          );
          dispatch(
            setCurrentTimezone(
              offsetMinutes < 0 ? `+${offsetFormatted}` : `-${offsetFormatted}`,
            ),
          );
        } catch (error) {
          console.error('Error fetching timezone:', error);
          return;
        }
      }
      if (project?.meta.currentProject?.id) {
        try {
          const currency = project.meta.currentProject?.projectCurrency[0];
          await dispatch(fetchAllData({ currency }));
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };
    fetchData();
    dispatch(fetchProjectList());
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1000 && window.innerWidth <= 1000);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [project?.timezone, project?.meta.currentProject?.id]);

  const closeDrawer = () => {
    setShowDrawer(false);
  };

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  const onChange = (key: any) => {
    return key;
  };

  const handleProjectSelect = async (projectId: number) => {
    if (projectId) {
      dispatch(sendProjectId({ projectId }))
        .unwrap()
        .then(responseData => {
          dispatch(setCurrentProject(responseData));
          dispatch(
            fetchAllData({
              currency: responseData.projectCurrency[0],
            }),
          );
        })
        .catch(error => {
          console.error('Error dispatching sendProjectId:', error);
        });
    }
    toggleDrawer();
  };

  if (isLoading && !isSmallScreen) {
    return <GlobalLoader />;
  }

  return (
    <div>
      {!showDrawer && (
        <ProjectDrawer
          visible={showDrawer}
          onClose={closeDrawer}
          onProjectSelect={handleProjectSelect}
        />
      )}
      {isSmallScreen ? (
        <div className={classes.dashboard}>
          <MobileFilters />
          <Tabs defaultActiveKey='1' items={tabsConfig} onChange={onChange} />
        </div>
      ) : (
        <div className={classes.home}>
          <FiltersContainer />
          <GeneralInfo isMobile={false} />
          <FinanceInfo dataSource={financeData} />
          <PaymentsInfo dataSource={paymentData} />
        </div>
      )}
    </div>
  );
};

export default Dashboard;
